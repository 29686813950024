import React from 'react';
import './assets/healthCheck.scss';

export default function HealthCheck() {
    return (
        <div>
            <div id="health-check" className={process.env.NODE_ENV === 'production' ? 'health-check-live' : 'health-check'}>
                {/*{process.env.NODE_ENV}*/}
                {/*&nbsp;-&nbsp;*/}
                {process.env.REACT_APP_BUILD_NAME}
                &nbsp;-&nbsp;
                {process.env.REACT_APP_BUILD_DATE}
            </div>

            <div className="bottom-spacer">
                &nbsp;
            </div>
        </div>
    )
}
