import React, {useState} from 'react';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./assets/search.scss";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSearch} from '@fortawesome/free-solid-svg-icons'

export default function Search() {
    const [searchStr, setSearchStr] = useState("");
    const regexText = /^[a-z0-9 ]{3,}$/i;
    const regexNumber = /^\d{1,4}$/;
    const regexMessier = /^me?s?s?i?e?r?\s*(\d{1,3})/i;
    const regexCaldwell = /^ca?l?d?w?e?l?l?\s*(\d{1,3})/i;
    const regexNgc = /^ng?c?\s*(\d{1,4})/i;
    const regexIc = /^ic?\s*(\d{1,4})/i;

    const handleSubmit = (event: any) => {
        event.preventDefault();
        let num = 0;
        let match = undefined;

        switch (true) {
            case regexNumber.test(searchStr):
                num = Number(searchStr);

                if (num >= 1 && num <= 110) {
                    window.location.replace(`/obj/m/${num}`);
                } else if (num > 110 && num <= 7840) {
                    window.location.replace(`/obj/ngc/${num}`);
                } else {
                    toast(<div><p>For Messier enter a number between 1 and 110</p><p>For NGC Enter a number less than 7840.</p></div>);
                }

                break;
            case regexMessier.test(searchStr):
                match = searchStr.match(regexMessier);

                if (match && match.length > 0) {
                    num = Number(match[1]);

                    if (num >= 1 && num <= 110) {
                        window.location.replace(`/obj/m/${num}`);
                    } else {
                        toast(<p>For Messier objects enter Mnnn where nnn is between 1 and 110 inclusive.</p>)
                    }
                } else {
                    toast(<p>For Messier objects enter Mnnn where nnn is between 1 and 110 inclusive.</p>)
                }

                break;
            case regexCaldwell.test(searchStr):
                match = searchStr.match(regexCaldwell);

                if (match && match.length > 0) {
                    num = Number(match[1]);

                    if (num >= 1 && num <= 109) {
                        window.location.replace(`/obj/c/${num}`);
                    } else {
                        toast(<p>For Caldwell objects enter Cnnn where nnn is between 1 and 109 inclusive.</p>)
                    }
                } else {
                    toast(<p>For Caldwell objects enter Cnnn where nnn is between 1 and 109 inclusive.</p>)
                }

                break;
            case regexIc.test(searchStr):
                match = searchStr.match(regexIc);

                if (match && match.length > 0) {
                    num = Number(match[1]);

                    if (num >= 1 && num <= 5386) {
                        window.location.replace(`/obj/ic/${num}`);
                    } else {
                        toast(<p>For IC objects enter ICnnnn or Innnn where nnn is between 1 and 5386 inclusive.</p>)
                    }
                } else {
                    toast(<p>For IC objects enter ICnnnn or Innnn where nnn is between 1 and 5386 inclusive.</p>)
                }

                break;
            case regexNgc.test(searchStr):
                match = searchStr.match(regexNgc);

                if (match && match.length > 0) {
                    num = Number(match[1]);

                    if (num >= 1 && num <= 7840) {
                        window.location.replace(`/obj/ngc/${num}`);
                    } else {
                        toast(<p>For NGC objects enter NGCnnnn or Nnnnn where nnn is between 1 and 7840 inclusive.</p>)
                    }
                } else {
                    toast(<p>For NGC objects enter NGCnnnn or Nnnnn where nnn is between 1 and 7840 inclusive.</p>)
                }

                break;
            default:
                if (regexText.test(searchStr)) {
                    window.location.replace(`/search/${searchStr}`);
                } else {
                    toast(<div><p>To search by text please enter at least 3 characters.</p><p>For example "blue".</p></div>)
                }

                break;
        }
    }

    return (
        <div className="search_container">
            <form onSubmit={handleSubmit}>
                <div className="search_input_icon">
                    <FontAwesomeIcon icon={faSearch}/>
                </div>

                <input type="text"
                       className="search_input"
                       placeholder="Object Search... e.g: 63, M63, 5055, ngc5055, Sunflower"
                       title="Enter your search term and hit enter"
                       onChange={(e) => setSearchStr(e.target.value)}
                />
            </form>
        </div>
    );
}
