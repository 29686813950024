import React from "react";
import './App.scss';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import LoadingSpinner from "./components/common/LoadingSpinner";
import Header from './components/header/Header';
import HeaderFixed from './components/headerfixed/HeaderFixed';
import Footer from './components/footer/Footer';
import HealthCheck from './components/healthcheck/HealthCheck';
import {ToastContainer} from "react-toastify";
import ScrollToTop from "./pages/ScrollToTop";

// Home page
const Home = React.lazy(() => import('./pages/Home'));
const About = React.lazy(() => import('./pages/home/About'));
const ContactMe = React.lazy(() => import('./pages/home/ContactMe'));
const DonateOrSayThanksForATalk = React.lazy(() => import('./pages/home/DonateOrSayThanksForATalk'));
const SiteMap = React.lazy(() => import('./pages/home/SiteMap'));
// Code
const Code = React.lazy(() => import('./pages/code/Code'));
const AndroidOpenGLTextureMappedSpheres = React.lazy(() => import('./pages/code/AndroidOpenGLTextureMappedSpheres'));
const DeepSkyLogger = React.lazy(() => import('./pages/code/DeepSkyLogger'));
const ThisWebSite = React.lazy(() => import('./pages/code/ThisWebSite'));
const DeepSkyStackerImageHelper = React.lazy(() => import('./pages/code/DeepSkyStackerImageHelper'));
// 18-inch dobsonian ATM
const ATM18DobsonianTerminusLux = React.lazy(() => import('./pages/dobsonian/ATM18DobsonianTerminusLux'));
const Overview = React.lazy(() => import('./pages/dobsonian/Overview'));
const TerminusLux = React.lazy(() => import('./pages/dobsonian/TerminusLux'));
const SecondaryCage = React.lazy(() => import('./pages/dobsonian/SecondaryCage'));
const ScopePlans = React.lazy(() => import('./pages/dobsonian/ScopePlans'));
const MirrorCell = React.lazy(() => import('./pages/dobsonian/MirrorCell'));
const MirrorBox = React.lazy(() => import('./pages/dobsonian/MirrorBox'));
const RockerBox = React.lazy(() => import('./pages/dobsonian/RockerBox'));
const Poles = React.lazy(() => import('./pages/dobsonian/Poles'));
const Bearings = React.lazy(() => import('./pages/dobsonian/Bearings'));
const Control = React.lazy(() => import('./pages/dobsonian/Control'));
const CompletedScope = React.lazy(() => import('./pages/dobsonian/CompletedScope'));
// Projects
const Projects = React.lazy(() => import('./pages/projects/Projects'));
const ALowCostEQ6TelescopePier = React.lazy(() => import('./pages/projects/ALowCostEQ6TelescopePier'));
const BuildingAFlipTopObservatory = React.lazy(() => import('./pages/projects/BuildingAFlipTopObservatory'));
const EQ6ControlWithEQMODOnWindows10 = React.lazy(() => import('./pages/projects/EQ6controlWithEQMODonWindows10'));
const TipsAndTricks = React.lazy(() => import('./pages/projects/TipsAndTricks'));
// Media
const Media = React.lazy(() => import('./pages/media/Media'));
const Books = React.lazy(() => import('./pages/media/Books'));
const AstronomySoftwarePackages = React.lazy(() => import('./pages/media/AstronomySoftwarePackages'));
const PodcastsAstronomicalAndScientific = React.lazy(() => import('./pages/media/PodcastsAstronomicalAndScientific'));
const Kit = React.lazy(() => import('./pages/media/Kit'));
// Star Charts and Catalogues
const Catalogues = React.lazy(() => import('./pages/catalogues/Catalogues'));
const InteractiveStarChart = React.lazy(() => import('./pages/catalogues/Interactivestarchart'));
const MessierMarathon = React.lazy(() => import('./pages/catalogues/MessierMarathon'));
const Objects = React.lazy(() => import('./pages/catalogues/Objects'));
const Constellation = React.lazy(() => import('./pages/catalogues/Constellation'));
const ConstellationType = React.lazy(() => import('./pages/catalogues/ConstellationType'));
const ConstellationTirion = React.lazy(() => import('./pages/catalogues/ConstellationTirion'));
const ConstellationRa = React.lazy(() => import('./pages/catalogues/ConstellationRa'));
const Planisphere = React.lazy(() => import('./pages/catalogues/Planisphere'));
const Almanac = React.lazy(() => import('./pages/catalogues/Almanac'));
const Search = React.lazy(() => import('./pages/catalogues/SearchPage'));
const TirionCharts = React.lazy(() => import('./pages/catalogues/Tirioncharts'));
const ListMessier = React.lazy(() => import('./pages/catalogues/ListMessier'));
const ListCaldwell = React.lazy(() => import('./pages/catalogues/ListCaldwell'));
const ListHerschel = React.lazy(() => import('./pages/catalogues/ListHerschel'));
const ListOther = React.lazy(() => import('./pages/catalogues/ListOther'));
const ListCarbon = React.lazy(() => import('./pages/catalogues/ListCarbon'));
const EditableAllSkyChart = React.lazy(() => import('./pages/catalogues/EditableAllSkyChart'));
const ObjectsByConstellation = React.lazy(() => import('./pages/catalogues/ObjectsbyConstellation'));
const ObjectsByType = React.lazy(() => import('./pages/catalogues/ObjectsbyType'));
const DeepSkyObjectsNearStars = React.lazy(() => import('./pages/catalogues/DeepSkyObjectsNearStars'));
const PlanetaryNebulae = React.lazy(() => import('./pages/catalogues/PlanetaryNebulae'));
const MonthlyList = React.lazy(() => import('./pages/catalogues/MonthlyList'));
const DataCredits = React.lazy(() => import('./pages/catalogues/DataCredits'));
const SynScanAlignmentStars = React.lazy(() => import('./pages/catalogues/SynScanalignmentstars'));
// Users
const Dashboard = React.lazy(() => import('./pages/user/Dashboard'));
const MosaicMessier = React.lazy(() => import('./pages/user/MosaicMessier'));
const MosaicCaldwell = React.lazy(() => import('./pages/user/MosaicCaldwell'));
const MosaicHerschel = React.lazy(() => import('./pages/user/MosaicHerschel'));
const MosaicOther = React.lazy(() => import('./pages/user/MosaicOther'));
const EquipmentSummary = React.lazy(() => import('./pages/user/EquipmentSummary'));
const ObservingSummary = React.lazy(() => import('./pages/user/ObservingSummary'));
const ImagingSummary = React.lazy(() => import('./pages/user/ImagingSummary'));

// 404
const PageNotFound = React.lazy(() => import('./pages/PageNotFound'));

function App() {
    return (
        <div className="App" id="whole-page">
            {/*<SiteMenu pageWrapId={"inner-page"} outerContainerId={"whole-page"}/>*/}

            <main id="inner-page">
                <Router>
                    <ScrollToTop/>
                    <HeaderFixed/>
                    <Header/>
                    <ToastContainer position="top-right" autoClose={4000} closeOnClick newestOnTop theme="dark"/>

                    <React.Suspense fallback={<LoadingSpinner/>}>
                        <Routes>
                            {/*<Route path="/" element={<Layout/>}>*/}
                            <Route>
                                {/* Home */}
                                <Route index element={<Home/>}/>
                                {/* http://localhost:3000/ */}
                                <Route path="/about" element={<About/>}/>
                                {/* http://localhost:3000/about */}
                                <Route path="/contactme" element={<ContactMe/>}/>
                                {/* http://localhost:3000/contactme */}
                                <Route path="/donateorsaythanksforatalk" element={<DonateOrSayThanksForATalk/>}/>
                                {/* http://localhost:3000/donateorsaythanksforatalk */}
                                <Route path="/sitemap" element={<SiteMap/>}/>
                                {/* http://localhost:3000/sitemap */}

                                {/* 18-inch Dobsonian ATM */}
                                <Route path="/atm18dobsonianterminuslux" element={<ATM18DobsonianTerminusLux/>}/>
                                {/* http://localhost:3000/atm18dobsonianterminuslux */}
                                <Route path="/overview" element={<Overview/>}/>
                                {/* http://localhost:3000/overview */}
                                <Route path="/terminuslux" element={<TerminusLux/>}/>
                                {/* http://localhost:3000/terminuslux */}
                                <Route path="/scopeplans" element={<ScopePlans/>}/>
                                {/* http://localhost:3000/scopeplans */}
                                <Route path="/secondarycage" element={<SecondaryCage/>}/>
                                {/* http://localhost:3000/secondarycage */}
                                <Route path="/mirrorcell" element={<MirrorCell/>}/>
                                {/* http://localhost:3000/mirrorcell */}
                                <Route path="/mirrorbox" element={<MirrorBox/>}/>
                                {/* http://localhost:3000/mirrorbox */}
                                <Route path="/rockerbox" element={<RockerBox/>}/>
                                {/* http://localhost:3000/rockerbox */}
                                <Route path="/poles" element={<Poles/>}/>
                                {/* http://localhost:3000/poles */}
                                <Route path="/bearings" element={<Bearings/>}/>
                                {/* http://localhost:3000/bearings */}
                                <Route path="/control" element={<Control/>}/>
                                {/* http://localhost:3000/control */}
                                <Route path="/completedscope" element={<CompletedScope/>}/>
                                {/* http://localhost:3000/completedscope */}

                                {/* Code */}
                                <Route path="/code" element={<Code/>}/>
                                {/* http://localhost:3000/code */}
                                <Route path="/deepskystackerimagehelper" element={<DeepSkyStackerImageHelper/>}/>
                                {/* http://localhost:3000/deepskystackerimagehelper */}
                                <Route path="/androidopengltexturemappedspheres" element={<AndroidOpenGLTextureMappedSpheres/>}/>
                                {/* http://localhost:3000/androidopengltexturemappedspheres */}
                                <Route path="/deepskylogger" element={<DeepSkyLogger/>}/>
                                {/* http://localhost:3000/deepskylogger */}
                                <Route path="/thiswebsite" element={<ThisWebSite/>}/>
                                {/* http://localhost:3000/thiswebsite */}

                                {/* Projects */}
                                <Route path="/projects" element={<Projects/>}/>
                                {/* http://localhost:3000/projects */}
                                <Route path="/alowcosteq6telescopepier" element={<ALowCostEQ6TelescopePier/>}/>
                                {/* http://localhost:3000/alowcosteq6telescopepier */}
                                <Route path="/buildingafliptopobservatory" element={<BuildingAFlipTopObservatory/>}/>
                                {/* http://localhost:3000/buildingafliptopobservatory */}
                                <Route path="/eq6controlwitheqmodonwindows10" element={<EQ6ControlWithEQMODOnWindows10/>}/>
                                {/* http://localhost:3000/eq6controlwitheqmodonwindows10 */}
                                <Route path="/tipsandtricks" element={<TipsAndTricks/>}/>
                                {/* http://localhost:3000/tipsandtricks */}

                                {/* Media */}
                                <Route path="/media" element={<Media/>}/>
                                {/* http://localhost:3000/media */}
                                <Route path="/books" element={<Books/>}/>
                                {/* http://localhost:3000/books */}
                                <Route path="/astronomysoftwarepackages" element={<AstronomySoftwarePackages/>}/>
                                {/* http://localhost:3000/astronomysoftwarepackages */}
                                <Route path="/podcastsastronomicalandscientific" element={<PodcastsAstronomicalAndScientific/>}/>
                                {/* http://localhost:3000/podcastsastronomicalandscientific */}
                                <Route path="/kit" element={<Kit/>}/>
                                {/* http://localhost:3000/kit */}

                                {/* Catalogues */}
                                <Route path="/catalogues" element={<Catalogues/>}/>
                                {/* http://localhost:3000/catalogues */}
                                <Route path="/list-messier" element={<ListMessier/>}/>
                                {/* http://localhost:3000/list-messier */}
                                <Route path="/list-caldwell" element={<ListCaldwell/>}/>
                                {/* http://localhost:3000/list-caldwell */}
                                <Route path="/list-herschel" element={<ListHerschel/>}/>
                                {/* http://localhost:3000/list-herschel */}
                                <Route path="/list-other" element={<ListOther/>}/>
                                {/* http://localhost:3000/list-other */}
                                <Route path="/list-carbon" element={<ListCarbon/>}/>
                                {/* http://localhost:3000/list-carbon */}
                                <Route path="/editableallskychart" element={<EditableAllSkyChart/>}/>
                                {/* http://localhost:3000/editableallskychart */}
                                <Route path="/objectsbyconstellation" element={<ObjectsByConstellation/>}/>
                                {/* http://localhost:3000/objectsbyconstellation */}
                                <Route path="/objectsbytype" element={<ObjectsByType/>}/>
                                {/* http://localhost:3000/objectsbytype */}
                                <Route path="/deepskyobjectsnearstars" element={<DeepSkyObjectsNearStars/>}/>
                                {/* http://localhost:3000/deepskyobjectsnearstars */}
                                <Route path="/planetarynebulae" element={<PlanetaryNebulae/>}/>
                                {/* http://localhost:3000/planetarynebulae */}
                                <Route path="/monthlylist" element={<MonthlyList/>}/>
                                {/* http://localhost:3000/monthlylist */}
                                <Route path="/synscanalignmentstars" element={<SynScanAlignmentStars/>}/>
                                {/* http://localhost:3000/synscanalignmentstars */}
                                <Route path="/datacredits" element={<DataCredits/>}/>
                                {/* http://localhost:3000/datacredits */}

                                {/* Star Charts */}
                                <Route path="/messiermarathon" element={<MessierMarathon/>}/>
                                {/* http://localhost:3000/messiermarathon */}
                                <Route path="/planisphere" element={<Planisphere/>}/>
                                {/* http://localhost:3000/planisphere */}
                                <Route path="/almanac" element={<Almanac/>}/>
                                {/* http://localhost:3000/almanac */}
                                <Route path="/tirioncharts" element={<TirionCharts/>}/>
                                {/* http://localhost:3000/tirioncharts */}

                                {/* Dynamic pages. */}
                                <Route path={`/tirion/:chartNumber`} element={<ConstellationTirion/>}/>
                                {/* http://localhost:3000/tirion/9 */}
                                {/* https://jimscosmos.com/constellationTir9 */}

                                <Route path={`/constellation/:abbr`} element={<Constellation/>}/>
                                {/* http://localhost:3000/constellation/tau */}
                                {/* https://www.jimscosmos.com/constellation/tau */}

                                <Route path={`/obj/:catalogue/:id`} element={<Objects/>}/>
                                <Route path={`/obj/:catalogue`} element={<Objects/>}/>
                                {/* http://localhost:3000/obj/M/1 */}
                                {/* http://localhost:3000/obj/m/45 */}
                                {/* http://localhost:3000/obj/m/40 */}
                                {/* http://localhost:3000/obj/NGC/1746 */}
                                {/* http://localhost:3000/obj/n/2186 */}
                                {/* http://localhost:3000/obj/cs/19 */}
                                {/* http://localhost:3000/obj/cs/60 */}
                                {/* http://localhost:3000/obj/3c/273 */}
                                {/* http://localhost:3000/obj/pease/1 */}
                                {/* https://www.jimscosmos.com/obj/M/1 */}
                                {/* https://www.jimscosmos.com/obj/NGC/1746 */}
                                {/* https://www.jimscosmos.com/obj/n/2186 */}

                                <Route path={`/ra/:ra`} element={<ConstellationRa/>}/>
                                {/* http://localhost:3000/ra/2 */}
                                {/* http://localhost:3000/ra/18 */}
                                {/* https://www.jimscosmos.com/constellationRA/18 */}
                                {/* https://www.jimscosmos.com/constellationRA/1 */}

                                <Route path={"/search"} element={<Search/>}/>
                                <Route path={"/search/:search"} element={<Search/>}/>
                                {/* http://localhost:3000/search/crab+nebula/ */}
                                {/* https://www.jimscosmos.com/search/crab+nebula/ */}

                                <Route path="/chart" element={<InteractiveStarChart/>}/>
                                <Route path="/chart/:ra/:dec/:radius/:height/:width" element={<InteractiveStarChart/>}/>
                                {/* http://localhost:3000/chart/4/0/2000/800/1600 */}
                                {/* https://www.jimscosmos.com/chart/1.189897/0/2000/800/1600 */}

                                <Route path={`/type/:type`} element={<ConstellationType/>}/>
                                {/* http://localhost:3000/type/pn */}
                                {/* http://localhost:3000/type/gx */}
                                {/* http://localhost:3000/type/nb */}
                                {/* http://localhost:3000/type/sc */}
                                {/* http://localhost:3000/type/oc */}
                                {/* http://localhost:3000/type/gc */}

                                {/* Users */}
                                <Route path="/dashboard" element={<Dashboard/>}/>
                                {/* http://localhost:3000/dashboard */}

                                {/* TODO: P2: Allow for free text description with ' at start, i.e don't translate these. */}

                                {/* TODO: P2: Distance Ladder. */}
                                {/* TODO: P3: USER LOGIN. */}

                                <Route path="/mosaicmessier" element={<MosaicMessier/>}/>
                                <Route path="/mosaiccaldwell" element={<MosaicCaldwell/>}/>
                                <Route path="/mosaicherschel" element={<MosaicHerschel/>}/>
                                <Route path="/mosaicother" element={<MosaicOther/>}/>

                                <Route path="/equipmentsummary" element={<EquipmentSummary/>}/>
                                {/* TODO: P3: Equipment setup */}

                                <Route path="/observingsummary" element={<ObservingSummary/>}/>
                                {/* TODO: P3: Observing summary */}

                                <Route path="/imagingsummary" element={<ImagingSummary/>}/>
                                {/* TODO: P3: Imaging summary/dashboard */}

                                {/*<Route path="/temperature" element={Temperature} /> */}
                                {/* TODO: P4: Greenhouse temps */}

                                {/*<Route path="/lunar" element={Lunar} /> */}
                                {/* TODO: P3: Lunar information */}

                                {/*<Route path="/solaractivity" element={SolarActivity} /> */}
                                {/* TODO: P3: Solar/Aurora information */}

                                {/*<Route path="/planets" element={Planets} /> */}
                                {/* TODO: P3: Planet information */}

                                {/*<Route path="/upcoming" element={Upcoming} /> */}
                                {/* TODO: P3: Upcoming events information, inc meteor showers. */}

                                {/*<Route path="/comets" element={Comets} /> */}
                                {/* TODO: P3: Comet information */}

                                {/* TODO: P4: Plot current object, fainter DS even if searched for are not shown on charts. */}
                                {/* TODO: P4: rise set transit. */}
                                {/* TODO: P4: LST/GMT etc. */}
                                {/* TODO: P4: Double loading of api when on a page. */}
                                {/* TODO: P4: Loading spinners, are they all there? Planisphere, Almanac. */}
                                {/* TODO: P4: Icons for podcasts design. */}
                                {/* TODO: P4: Telrad circle diameters! */}
                                {/* TODO: P4: Red button design for PDF download. */}
                                {/* TODO: P4: Media design. */}
                                {/* TODO: P4: DeepSkyList filter. */}
                                {/* TODO: P4: Star colours. */}
                                {/* TODO: P4: Names etc for User objects, Leo 1 etc. from objects near stars. DB DATA FIX. */}

                                {/* 404 */}
                                <Route path="*" element={<PageNotFound/>}/>
                            </Route>
                        </Routes>
                    </React.Suspense>

                    <Footer/>
                    <HealthCheck/>
                </Router>
            </main>
        </div>
    );
}

export default App;
